<template>
  <div>
    <div class="text-center pt-4" v-if="loading">
      <b-spinner label="Cargando..."/>
      Cargando...
    </div>

    <b-alert
        variant="warning"
        v-if="errorMessage"
        show
    >
      <div class="alert-body font-small-2">
        <p>
          <small class="mr-50">
            <span class="font-weight-bold">{{ errorMessage }}</span>
          </small>
        </p>
      </div>
    </b-alert>

    <b-form @submit.prevent="save()">
      <b-row>
        <b-col>
          <b-form-group
              label="Locación"
          />
        </b-col>
      </b-row>
      <!--End SubTitle-->
      <b-row>
        <b-col cols="6">
          <label>
            Fecha
          </label>
          <b-form-group>
            <b-form-datepicker
                id="dateE-datepicker"
                v-model="selectedSince"
                class="mb-1"
                placeholder="Fecha"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="es"
                @input="formatDateSince(selectedSince + 'T23:59:59.999Z')"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Semana Epidemiológico" label-for="weekE">
            <b-form-input id="weekE" type="text" v-model="item.week"></b-form-input>
          </b-form-group>
        </b-col>        
        <b-col cols="6">
          <b-form-group
              label="Región"
              label-for="region"
          >
            <b-form-select
                v-model="region"
                :options="locations.regiones"
                options-field="none"
                id="region"
                text-field="region"
                @change="getDistritos()"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Distrito"
              label-for="district"
          >
            <b-form-select
                v-model="district"
                :options="locations.distritos"
                options-field="none"
                id="distrito"
                text-field="distrito"
                @change="getCorregimientos()"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
              label="Corregimiento"
              label-for="corregimiento"
          >
            <b-form-select
                v-model="corregimiento"
                :options="locations.corregimientos"
                options-field="none"
                id="corregimiento"
                text-field="corregimiento"
                @change="getLocalidades()"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
              label="Localidad"
              label-for="localidad"
          >
            <b-form-select
                v-model="localidad"
                :options="locations.localidades"
                options-field="none"
                id="localidad"
                text-field="localidad"
                @change="getFocus()"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Foco" label-for="focus">
            <b-form-input id="focus" disabled v-model="item.focus"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>

      <b-row>
        <b-col cols="2" v-for="field in fields.slice(0, 1)" :key="field.field">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <hr>
      <b-row>
        <b-col cols="4"><strong>Visitas</strong></b-col>
        <b-col offset="1"><strong>Muestras Tomadas</strong></b-col>
      </b-row>
      <b-row>
        <!-- First set of columns -->
        <b-col cols="1" v-for="field in fields.slice(1, 10)" :key="field.field" :offset="field.offset">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="4"><strong>Positivos</strong></b-col>
      </b-row>
      <b-row>
        <!-- Second set of columns -->
        <b-col cols="1" v-for="field in fields.slice(10, 14)" :key="field.field" :offset="field.offset">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <h6>Intervenciones</h6>
      <b-row>
        <b-col cols="1"></b-col>
        <b-col cols="2"><strong>Neb</strong></b-col>
        <b-col offset="1"><strong>Químico</strong></b-col>
      </b-row>
      <b-row>
        <!-- Third set of columns -->
        <b-col cols="1" v-for="field in fields.slice(14, 19)" :key="field.field" :offset="field.offset">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
<hr>
      <b-row>
        <b-col cols="2"><strong>Colecta de Adultos</strong></b-col>
        <b-col offset="1"><strong>Control Lavario / Nº de Criadero Anophelinos</strong></b-col>
      </b-row>
      <b-row>
        <!-- Fourth set of columns -->
        <b-col cols="1" v-for="field in fields.slice(19)" :key="field.field" :offset="field.offset">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        
        <b-col cols="12" class="mt-2">
          <b-alert
              variant="warning"
              v-if="errorMessage"
              show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50">
                  <span class="font-weight-bold">{{ errorMessage }}</span>
                </small>
              </p>
            </div>
          </b-alert>
        </b-col>

        <b-col class="mt-2 mb-4">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            <span v-if="!loading">Guardar</span>
            <span v-if="loading">Un momento...</span>
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="$router.go(-1)"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BAlert, BAvatar, BBadge, BPagination, BForm, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BContainer,
  BButton, BDropdown, BDropdownItem, BFormDatepicker, BToast, BSpinner, BFormFile, BFormSelectOption
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import environment from "@/environment";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {format} from "date-fns";

export default {
  name: 'MalariaForm',
  components: {
    BAlert,
    BAvatar,
    BBadge,
    BCardCode,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BContainer,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BToast,
    BSpinner,
    BFormFile,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedSince: null,
      region: '',
      district: '',
      corregimiento: '',
      localidad: '',
      fields: [
        {field: 'localidades', label: 'Localidades Trabajadas', type: 'number' },
        {field: 'casas1', label: 'Casas', type: 'number' },
        {field: 'centEduc', label: 'Cent. Educ.', type: 'number' },
        {field: 'autLocales', label: 'Aut. Locales', type: 'number' },
        {field: 'instSalud', label: 'Inst. Salud', type: 'number'},
        {field: 'prd', label: 'PDR', type: 'number', offset: 1  },
        {field: 'prdPositivo', label: 'PDR Positivos', type: 'number' },
        {field: 'gg', label: 'GG', type: 'number' },
        {field: 'ggPositivo', label: 'GG Positivo', type: 'number' },
        {field: 'totalPositivos', label: 'Total positivas', type: 'number' },
        {field: 'tratamientos', label: 'Tratamientos', type: 'number' },
        {field: 'investigados', label: 'Investigados', type: 'number' },
        {field: 'vivax', label: 'Vivax', type: 'number' },
        {field: 'falciparun', label: 'Falciparun ', type: 'number' },
        {field: 'roc1', label: 'Roc', type: 'number' },
        {field: 'casas2', label: 'Casas', type: 'number' },
        {field: 'abrigos', label: 'Abrigos', type: 'number' },
        {field: 'roc2', label: 'Roc / Kg', type: 'number', offset: 1 },
        {field: 'neb', label: 'Neb / Lt', type: 'number' },
        {field: 'nMosquitos', label: 'N° de mosquitos', type: 'number' },
        {field: 'enc', label: 'Enc.', type: 'number', offset: 2 },
        {field: 'trat', label: 'Trat.', type: 'number' },
        {field: 'larvGast', label: 'Larv. Gast/lbs', type: 'number' },
        {field: 'eval', label: 'Eval.', type: 'number' }
      ],
      item: {},
      errorMessage: '',
      loading:false,
      locations: {
        regiones: [],
        corregimientos: [],
        distritos: [],
        localidades: []
      },
    }
  },
  mounted() {
    this.getRegiones()
  },
  methods: {
    async getRegiones() {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}stats/regiones`)
        this.locations.regiones = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async getDistritos() {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}stats/distritos?id_region=${this.region.id_region}`)
        this.locations.distritos = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async getCorregimientos() {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}stats/corregimientos?id_region=${this.region.id_region}&codigo_distrito=${this.district.codigo_distrito}`)
        this.locations.corregimientos = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async getLocalidades() {
      try {
        this.loading = true        
        const resp = await this.$http.get(`${environment.uri}stats/localidades?id_region=${this.region.id_region}&codigo_distrito=${this.district.codigo_distrito}&codigo_corregimiento=${this.corregimiento.codigo_corregimiento}`)
        this.locations.localidades = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async getFocus() {
      try {
        this.loading = true        
        const resp = await this.$http.get(`${environment.uri}focus/${localidad}`)
        this.item.focus = resp.data
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async save() {
      try {
        this.loading = true
        this.errorMessage = ''

        this.item.regionLabel = this.region.region
        this.item.districtLabel = this.district.distrito
        this.item.corregimientoLabel = this.corregimiento.corregimiento
        this.item.localidadLabel = this.localidad.localidad

        this.item.region = this.region.id_region
        this.item.district = this.district.id_distrito
        this.item.corregimiento = this.corregimiento.id_corregimiento
        this.item.localidad = this.localidad.id_localidad

        await this.$http.post(`${environment.uri}stats/malaria`, this.item)

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Guardado`,
            icon: 'CoffeeIcon',
            variant: 'Info',
            text: `Has registrado exitosamente`,
          },
        })
        this.item = {}
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
        await this.$router.push('/stats/malaria/list')
      }
    },
    async formatDateSince(a) {
      try {
        this.loading = true
        const date = new Date(a);
        const fdate = format(date, 'yyyy-MM-dd')
        this.item.dateE = fdate
        const resp = await this.$http
        .get(`${environment.uri}weeks/${fdate}`)
        this.item.week = resp.data.week
      } catch(error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
/* Add any custom styles if needed */
</style>
